import React,{useState,Fragment} from 'react'
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import {
    changeIsActive
} from '../../context/example/layoutSlice'
const Footer = () => {
    const dispatch = useDispatch()
    return (
        <Fragment>
            <section id="footer" className="clearfix">
                <div className="container">
                    <div className="row">
                        <div className="footer clearfix">
                            <div className="col-sm-4 footer_left clearfix">
                                <div className="footer_left_inner">
                                    <h4><a href="index.html"><img src="img/2.jpg" className="img-circle" style={{ height: '60px' }} />Save Our Planet<span className="clubing_2"></span></a></h4>
                                    <p>Our mission to save environment by various method<br />which includes recycling, reduce, resuse<br /> reduce polllution technique for our <br /> future.
                                    </p>
                                    <ul>
                                        <li className="status_1"><a href="#"><i className="fa fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-google-plus-official"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-2 footer_midle clearfix">
                                <div className="footer_midle_inner">
                                    <h2>Menu</h2>
                                    <p><Link to='/' onClick={()=>{dispatch(changeIsActive('/'))}}>Home</Link></p>
                                    <p><Link to='/about' onClick={()=>{dispatch(changeIsActive('about'))}}>About</Link></p>
                                    <p><Link to='/newsletter' onClick={()=>{dispatch(changeIsActive('newsletter'))}}>News</Link></p>
                                    <p><Link to='/team' onClick={()=>{dispatch(changeIsActive('team'))}}>Teams</Link></p>
                                </div>
                            </div>
                            <div className="col-sm-2 footer_center clearfix">
                                <div className="footer_center_inner">
                                    <p><Link to='/gallery' onClick={()=>{dispatch(changeIsActive('gallery'))}}>Gallery</Link></p>
                                    <p><Link to='/contact' onClick={()=>{dispatch(changeIsActive('contact'))}}>Contact</Link></p>
                                    <p><a href="#">Faqs</a></p>
                                    <p><a href="#">Events</a></p>
                                </div>
                            </div>
                            <div className="col-sm-4 footer_right clearfix">
                                <div className="footer_right_inner">
                                    <h2>Contact Us</h2>
                                </div>
                                <div className="footer_right_inner_2 clearfix">
                                    <div className="col-sm-2 address_left clearfix">
                                        <div className="address_left_inner">
                                            <p><i className="fa fa-simplybuilt"></i></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-10 address_right clearfix">
                                        <div className="address_right_inner">
                                            <p>Porta Ad:</p>
                                            <h4>Kolkata</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer_right_inner_2 clearfix">
                                    <div className="col-sm-2 address_left clearfix">
                                        <div className="address_left_inner">
                                            <p><i className="fa fa-phone"></i></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-10 address_right clearfix">
                                        <div className="address_right_inner">
                                            <p>Call Us:</p>
                                            <h4><a href="#">(+91) 89729 50960</a></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer_right_inner_2 clearfix">
                                    <div className="col-sm-2 address_left clearfix">
                                        <div className="address_left_inner">
                                            <p><i className="fa fa-mail-forward"></i></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-10 address_right clearfix">
                                        <div className="address_right_inner">
                                            <p>Mail Us:</p>
                                            <h4><a href="#">rajdeepc67@gmail.com</a></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="footer_last" className="clearfix">
                <div className="container">
                    <div className="row">
                        <div className="footer_last clearfix">
                            <div className="col-sm-7 footer_last_left clearfix">
                                <div className="footer_last_left_inner">
                                    <p className="website">© 2022 Save Our Planet. All rights reserved | Design by<a href="http://www.templateonweb.com"> TemplateOnWeb</a></p>
                                </div>
                            </div>
                            <div className="col-sm-5 footer_last_right clearfix">
                                <div className="footer_last_right_inner">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-google"></i></a></li>
                                        <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Footer


