import React,{useEffect, useState, Suspense} from 'react'
import { httpRequest } from '../api';
import { APIBASE, GET, JSONCONTENTTYPE, ABOUTUS1, ABOUTUS2, ABOUTUS3, ABOUTUS4 } from '../api/constant';
const About = () => {

    const [aboutusPage1Data, setAboutUspage1Data] = useState({
        title: '',
        file: '',
        submit: null
    });
    
    const [aboutusPage2Data, setAboutUspage2Data] = useState({
        title: '',
        file: '',
        submit: null
    });

    const [aboutusPage3Data, setAboutUspage3Data] = useState({
        title: '',
        file: '',
        submit: null
    });
    
    const [aboutusPage4Data, setAboutUspage4Data] = useState({
        title: '',
        subTitle: '',
        files: [],
        submit: null
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        async function fetchAboutUsPage1Data() {
            const getIntialData = await httpRequest(ABOUTUS1 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setAboutUspage1Data(data);
            }
        }
        async function fetchAboutUsPage2Data() {
            const getIntialData = await httpRequest(ABOUTUS2 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setAboutUspage2Data(data);
            }
        }
        async function fetchAboutUsPage3Data() {
            const getIntialData = await httpRequest(ABOUTUS3 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setAboutUspage3Data(data);
            }
        }
        async function fetchAboutUsPage4Data() {
            const getIntialData = await httpRequest(ABOUTUS4 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;

                if (data.files.length>0) {
                    data.files.map((val)=>{
                        val.file= APIBASE + val.file.replace('public', '');
                    })
                }
                console.log('data', data);
                setAboutUspage4Data(data);
            }
        }
        fetchAboutUsPage1Data();
        fetchAboutUsPage2Data();
        fetchAboutUsPage3Data();
        fetchAboutUsPage4Data();
        console.log('aboutusPage1Data---->', aboutusPage1Data.file);
    }, []);
    
    return (
        <>
            <Suspense fallback={<h1>Loading data...</h1>}>
                <section id="about" className="clearfix" style={{backgroundImage: 'url('+aboutusPage1Data.file+')'}}>
                    <div className="about_head">
                        <div className="container">
                            <div className="row">
                                <div className="about_main clearfix">
                                    <h2>{aboutusPage1Data.title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
            <section id="about_2" className="clearfix"  style={{backgroundImage: 'url('+aboutusPage2Data.file+')'}}>
                <Suspense fallback={<h1>Loading data...</h1>}>
                    <div className="about_head_2">
                        <div className="container">
                            <div className="row">
                                <div className="about_2_main clearfix">
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm-4 about_2_main_left clearfix">
                                        <div className="about_2_main_left_inner">
                                            <h2>Save Our Planet with Us</h2>
                                            <p>{aboutusPage2Data.title}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </section>

            <section id="about_3" className="clearfix" style={{backgroundImage: 'url('+aboutusPage3Data.file+')'}}>
            <Suspense fallback={<h1>Loading data...</h1>}>

                <div className="container">
                    <div className="row">
                        <div className="about_3 clearfix">
                            <div className="col-sm-6 about_3_left clearfix">
                                <div className="about_3_left_inner">
                                    <h5>{aboutusPage3Data.title}</h5>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Suspense>

            </section>

            <section id="about_4" className="clearfix">
                <Suspense fallback={<h1>Loading data...</h1>}>

                    <div className="container">
                        <div className="row">
                            <div className="about_4 clearfix">
                                <h2>{aboutusPage4Data.title}</h2>
                            </div>
                            <div className="about_4_midddle clearfix">
                                <div className="middle_1_para clearfix">

                                    <ul className="nav nav-tabs">
                                    {aboutusPage4Data.files.length> 0 && aboutusPage4Data.files.map( (val,index)=>{
                                            let classname = index == 0 ? 'active':''
                                            let menuid = index == 0 ? '#home':`#menu${index}`

                                            return (
                                                <li className={classname}><a data-toggle="tab" href={menuid}>{val.title}</a></li>
                                                )
                                        }
                                        )}
                                    </ul>

                                    <div className="tab-content clearfix">
                                    {aboutusPage4Data.files.length> 0 && aboutusPage4Data.files.map( (val,index)=>{
                                        let classname = index == 0 ? 'tab-pane fade in active clearfix':'tab-pane fade clearfix'
                                        let menuid = index == 0 ? 'home':`menu${index}`

                                        return (
                                                <div id={menuid} className={classname}>
                                                    <div className="click clearfix">
                                                        <div className="about_4_midddle_top clearfix">
                                                            <div className="col-sm-5 about_4_midddle_left clearfix">
                                                                <div className="about_4_midddle_left_inner">
                                                                    <p><img src={val.file} /></p>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-7 about_4_midddle_right clearfix">
                                                                <div className="about_4_midddle_right_inner">
                                                                    <h2><i className="fa fa-quote-left"></i></h2>
                                                                    <p>{val.subTitle}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                    }
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </section>
        </>
    )
}

export default About


