import React,{useEffect, useState, Suspense} from 'react'
import { httpRequest } from '../api';
import { APIBASE, GET, JSONCONTENTTYPE, GALLERY1, GALLERY2 } from '../api/constant';


const Gallery = () => {
    const [galleryPage1Data, setGallerypage1Data] = useState({
        title: '',
        file: '',
        submit: null
    });
    const [galleryPage2Data, setGallerypage2Data] = useState({
        title: '',
        subTitle: '',
        files: [],
        submit: null
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        async function fetchData() {
            const getIntialData = await httpRequest(GALLERY1 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setGallerypage1Data(data);
            }
        }
        async function fetchHomepage4Data() {
            const getIntialData = await httpRequest(GALLERY2 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                
                if (data.files.length>0) {
                    data.files.map((val)=>{
                        val.file= APIBASE + val.file.replace('public', '');
                    })
                }
                console.log('data', data);
                setGallerypage2Data(data);
            }
        }
        fetchData();
        fetchHomepage4Data();
        console.log('galleryPage1Data---->', galleryPage1Data.file);
    }, []);
    return (
        <>
            <Suspense fallback={<h1>Loading data...</h1>} >
                <section id="about" className="clearfix" style={{backgroundImage: 'url('+galleryPage1Data.file+')'}}>
                    <div className="about_head">
                        <div className="container">
                            <div className="row">
                                <div className="about_main clearfix">
                                    <h2>{galleryPage1Data.title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>

            <section id="center_4" className="clearfix">
                <Suspense fallback={<h1>Loading data...</h1>} >

                    {galleryPage2Data.files.length> 0 && galleryPage2Data.files.map( (val,index)=>{
                        return (
                            <>
                                {index%2 == 0?
                                <div className="col-sm-6 center_4_left clearfix">
                                    <div className="center_4_left_inner">
                                        <div className="av-container av-visible"><article className="aniview animated slideInDown" data-av-animation="slideInDown" style={{opacity: 1}}>
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <a href={val.file} title={val.title} className="zoom" data-title={val.title} data-footer={val.subTitle} data-type="image" data-toggle="lightbox">
                                                        <img src={val.file} style={{height:"400px",width:"600px"}} alt="Sky Vibes Zone" />
                                                        <span className="overlay"><i className="glyphicon glyphicon-search"></i></span>
                                                    </a>
                                                </div>
                                            </div>
                                        </article></div>
                                    </div>
                                </div>
                                :
                                (<div className="col-sm-6 center_4_right clearfix">
                                    <div className="center_4_left_inner">
                                        <div className="av-container av-visible"><article className="aniview animated slideInDown" data-av-animation="slideInDown" style={{opacity: 1}}>
                                            <div className="panel panel-default">
                                                <div className="panel-body">
                                                    <a href={val.file} title={val.title} className="zoom" data-title={val.title} data-footer={val.subTitle} data-type="image" data-toggle="lightbox">
                                                        <img src={val.file} style={{height:"400px",width:"600px"}} alt="Sky Vibes Zone" />
                                                        <span className="overlay"><i className="glyphicon glyphicon-search"></i></span>
                                                    </a>
                                                </div>
                                            </div>
                                        </article></div>
                                    </div>
                                </div>)
                                }
                            </>
                        )}
                    )}
                </Suspense>
            </section>
        </>
    )
}

export default Gallery


