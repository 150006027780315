import React,{useEffect, useState, Suspense} from 'react'
import { httpRequest } from '../api';
import { APIBASE, GET, JSONCONTENTTYPE, TEAM1 } from '../api/constant';


const Team = () => {
    const [teamPage1Data, setTeampage1Data] = useState({
        title: '',
        file: '',
        submit: null
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        async function fetchData() {
            const getIntialData = await httpRequest(TEAM1 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setTeampage1Data(data);
            }
        }
        fetchData();
        console.log('teamPage1Data---->', teamPage1Data.file);
    }, []);
    return (
        <>
            <Suspense fallback={<h1>Loading data...</h1>}>
                <section id="about" class="clearfix" style={{backgroundImage: 'url('+teamPage1Data.file+')'}}>
                    <div class="about_head">
                        <div class="container">
                            <div class="row">
                                <div class="about_main clearfix">
                                    <h2>{teamPage1Data.title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
            <section id="center_7" class="center_7_team clearfix">
                <div class="container">
                    <div class="row">
                        <div class="center clearfix">
                            <h2>Coming Soon.....</h2>
                            <h4></h4>
                            <p><img src="img/38.jpg" /></p>
                            <h3></h3>
                            <h5></h5>
                        </div>
                        {/*
                        <div class="center_7_middle clearfix">
                            <div class="col-sm-4 center_7_middle_left clearfix">
                                <div class="center_7_middle_left_inner_1 clearfix">
                                    <div class="card">
                                        <div class="card-image">
                                            <img class="img-responsive" src="img/22.jpg"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="center_7_middle_left_inner_2 clearfix">
                                    <h2>Dignissim Lacinia</h2>
                                    <h4>3 adipiscing elit</h4>
                                    <ul>
                                        <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-google-plus-official"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-sm-4 center_7_middle_left clearfix">
                                <div class="center_7_middle_left_inner_1 clearfix">
                                    <div class="card">
                                        <div class="card-image">
                                            <img class="img-responsive" src="img/23.jpg"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="center_7_middle_left_inner_2 clearfix">
                                    <h2>Praesent Libero</h2>
                                    <h4>8 adipiscing elit</h4>
                                    <ul>
                                        <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-google-plus-official"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-sm-4 center_7_middle_left clearfix">
                                <div class="center_7_middle_left_inner_1 clearfix">
                                    <div class="card">
                                        <div class="card-image">
                                            <img class="img-responsive" src="img/24.jpg"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="center_7_middle_left_inner_2 clearfix">
                                    <h2>Mauris massa</h2>
                                    <h4>7 adipiscing elit</h4>
                                    <ul>
                                        <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-google-plus-official"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </section>
        </>
    )
}
export default Team


