export const GET = 'get';
export const POST = 'post';
export const PATCH = 'patch';
export const DELETE = 'delete';
export const MULTIPARTCONTENTTYPE = 'multipart/form-data';
export const JSONCONTENTTYPE = 'application/json';
export const APIBASE = 'https://saveourplanet.in:3001/';
//export const APIBASE = 'http://localhost:3001/';
export const LOGIN = 'api/v1/users/login';
export const SIGNUP = 'api/v1/users/signup';
export const HOMEPAGE1 = 'api/v1/home/page1';
export const HOMEPAGE2 = 'api/v1/home/page2';
export const HOMEPAGE3 = 'api/v1/home/page3';
export const HOMEPAGE4 = 'api/v1/home/page4';
export const HOMEPAGE5 = 'api/v1/home/page5';
export const HOMEPAGE6 = 'api/v1/home/page6';
export const HOMEPAGE7 = 'api/v1/home/page7';
export const ABOUTUS1 = 'api/v1/aboutus/page1';
export const ABOUTUS2 = 'api/v1/aboutus/page2';
export const ABOUTUS3 = 'api/v1/aboutus/page3';
export const ABOUTUS4 = 'api/v1/aboutus/page4';
export const NEWSLETTER1 = 'api/v1/newsletter/page1';
export const NEWSLETTER2 = 'api/v1/newsletter/page2';
export const TEAM1 = 'api/v1/team/page1';
export const CONTACTUS1 = 'api/v1/contactus/page1';
export const GALLERY1 = 'api/v1/gallery/page1';
export const GALLERY2 = 'api/v1/gallery/page2';
