import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import Header from './components/layout/Header'
import Login from './components/layout/Login'
import Home from './pages/Home'
import About from './pages/About'
import NewsLetter from './pages/NewsLetter'
import Contact from './pages/Contact'
import Gallery from './pages/Gallery'
import Team from './pages/Team'
import Prices from './pages/Prices'
import Footer from './components/layout/Footer'
// REDUX
// Import store and wrap app with redux provider
// import store from './store'

function App() {
  return (
    <div className="App">
        <Router>
          <Header/>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={Login} />
              <Route path="/about" component={About} />
              <Route path="/newsletter" component={NewsLetter} />
              <Route path="/contact" component={Contact} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/prices" component={Prices} />
              <Route path="/team" component={Team} />
            </Switch>
          <Footer/>
        </Router>

    </div>
  )
}

export default App
