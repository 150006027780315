import React,{useEffect, useState, Suspense} from 'react'
import { httpRequest } from '../../api';
import { APIBASE, GET, JSONCONTENTTYPE, HOMEPAGE1 } from '../../api/constant';

const Top = () => {
    const [homepage1Data, setHomepage1Data] = useState({
        title: '',
        subTitle: '',
        targetPlanted: '',
        actualPlanted: '',
        height: '',
        width: '',
        file: '',
        submit: null
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        async function fetchData() {
            const getIntialData = await httpRequest(HOMEPAGE1 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                    //setPreviewFile(APIBASE + data.file);
                }
                console.log('data', data);
                setHomepage1Data(data);
            }
        }
        fetchData();
        console.log('homepage1Data---->', homepage1Data);
    }, []);
  return (        
            <section id="top" className="clearfix" style={{backgroundImage: 'url('+homepage1Data.file+')'}}>
                <Suspense fallback={<h1>Loading data...</h1>}>
                    <div className="top_head">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="top clearfix">
                                        <h2>{homepage1Data.title.split('.')[0]}<br/> {homepage1Data.title.split('.')[1]}</h2>
                                        <h4>{homepage1Data.subTitle}</h4>
                                        <p><a href="#">TARGET TO PLANT TREE: {homepage1Data.targetPlanted}</a></p>
                                        <p><a href="#">PLANTED TILL NOW: {homepage1Data.actualPlanted}</a></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </section>
        )
}

export default Top


