import React,{useEffect, useState, Suspense} from 'react'
import { httpRequest } from '../api';
import { APIBASE, GET, JSONCONTENTTYPE, CONTACTUS1 } from '../api/constant';


const Contact = () => {
    const [contactUsPage1Data, setContactpage1Data] = useState({
        title: '',
        file: '',
        submit: null
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        async function fetchData() {
            const getIntialData = await httpRequest(CONTACTUS1 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setContactpage1Data(data);
            }
        }
        fetchData();
        console.log('contactUsPage1Data---->', contactUsPage1Data.file);
    }, []);
    return (
        <>
            <Suspense fallback={<h1>Loading data...</h1>} >
                <section id="about" className="clearfix" style={{backgroundImage: 'url('+contactUsPage1Data.file+')'}}>
                    <div className="about_head">
                        <div className="container">
                            <div className="row">
                                <div className="about_main clearfix">
                                    <h2>{contactUsPage1Data.title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="contact" class="clearfix">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 contact clearfix">
                                <div class="col-sm-12 contact_left clearfix">
                                    <div class="col-sm-12 contact_left_2 clearfix">
                                        <div class="col-sm-12 contact_left_2_right clearfix">
                                            <div class="contact_left_2_left_inner_1">
                                                <h3>CONTACT INFO</h3>
                                                <h5>Mobile: {contactUsPage1Data.mobile}</h5>
                                                <h5>Email:<a href="#">{contactUsPage1Data.email}</a></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                </div>
                            </div>
                </section>

            </Suspense>
                </>
           )
}

export default Contact


