import React,{useEffect, useState, Suspense} from 'react'
import { httpRequest } from '../api';
import { APIBASE, GET, JSONCONTENTTYPE, NEWSLETTER1, NEWSLETTER2 } from '../api/constant';

const NewsLetter = () => {

    const [newsLetterPage1Data, setNewsLetterpage1Data] = useState({
        title: '',
        file: '',
        submit: null
    });
    const [newsLetterPage2Data, setNewsLetterpage2Data] = useState({
        title: '',
        subTitle: '',
        link: '',
        files: [],
        submit: null
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        async function fetchNewsLetterPage1Data() {
            const getIntialData = await httpRequest(NEWSLETTER1 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setNewsLetterpage1Data(data);
            }
        }
        async function fetchNewsLetterPage2Data() {
            const getIntialData = await httpRequest(NEWSLETTER2 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                
                if (data.files.length>0) {
                    data.files.map((val)=>{
                        val.file= APIBASE + val.file.replace('public', '');
                    })
                }
                console.log('data', data);
                setNewsLetterpage2Data(data);
            }
        }
        fetchNewsLetterPage1Data();
        fetchNewsLetterPage2Data();
        console.log('newsLetterPage1Data---->', newsLetterPage1Data);
    }, []);

    return (        
        <>
            <Suspense fallback={<h1>Loading data...</h1>}>
                <section id="about" className="clearfix" style={{backgroundImage: 'url('+newsLetterPage1Data.file+')'}}>
                    <div className="about_head">
                        <div className="container">
                            <div className="row">
                                <div className="about_main clearfix">
                                    <h2>{newsLetterPage1Data.title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
            <section id="service_2" className="clearfix">
                <div className="container">
                  <Suspense fallback={<h1>Loading data...</h1>}>

                    <div className="row">
                        <div className="center clearfix">
                            <h2>{newsLetterPage2Data.title}</h2>
                            <h4>{newsLetterPage2Data.subTitle}</h4>
                        </div>
                        {newsLetterPage2Data.files.length> 0 && newsLetterPage2Data.files.map( (val)=>{
                            return (
                            <div className="service_2_middle clearfix">
                                <div className="col-sm-6 center_middle_left_service clearfix">
                                    <div className="center_7_middle_left_inner_1 clearfix">
                                        <div className="card">
                                            <div className="card-image">
                                                <img className="img-responsive" src={val.file}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 service_2_middle_right clearfix">
                                    <div className="service_2_middle_right_inner clearfix">
                                        <h2>{val.title}</h2>
                                        <p>{val.subTitle}</p>
                                            <h5><a href={val.link}  target="_">LEARN MORE</a></h5>
                                    </div>

                                </div>
                            </div>
                            )}
                        )}
                        {/* 
                        <div className="service_2_middle clearfix">
                            <div className="col-sm-6 center_middle_left_service clearfix">
                                <div className="center_7_middle_left_inner_1 clearfix">
                                    <div className="card">
                                        <div className="card-image">
                                            <img className="img-responsive" src="img/34.png"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 service_2_middle_right clearfix">
                                <div className="service_2_middle_right_inner clearfix">
                                    <h2>Biden Budgets $50M to Develop Floating Offshore Wind Industry</h2>
                                    <p> The Biden Administration is launching a fleet of coordinated actions <br/> to develop floating offshore wind platforms, an emerging  <br/>clean energy technology that will help the United States lead on offshore wind. <br/> Called the “Floating Offshore Wind Shot,” the new program will capture this potential <br/> to power millions of homes and businesses, grow new manufacturing and maritime industries, and tackle the climate crisis.</p>
                                        <h5><a href="https://ens-newswire.com/biden-budgets-50m-to-develop-floating-offshore-wind-industry/" target="_">LEARN MORE</a></h5>
                                    </div>
                                    </div>
                        </div>
                        <div className="service_2_middle clearfix">
                            <div className="col-sm-6 center_middle_left_service clearfix">
                                <div className="center_7_middle_left_inner_1 clearfix">
                                    <div className="card">
                                        <div className="card-image">
                                            <img className="img-responsive" src="img/35.png"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 service_2_middle_right clearfix">
                                <div className="service_2_middle_right_inner clearfix">
                                    <h2>Global Net Zero Carbon Energy by 2050 Possible, Profitable</h2>
                                    <p>The research shows a win-win-win scenario, in which rapidly transitioning<br/> to clean energy results in lower energy system costs than a fossil fuel system, <br/>while providing more energy to the global economy, and expanding energy access <br/>to more people around the world.</p>
                                        <h5><a href="https://ens-newswire.com/global-net-zero-carbon-energy-by-2050-possible-profitable/" target="_">LEARN MORE</a></h5>
                                    </div>
                                    </div>
                        </div>
                        <div className="service_2_middle clearfix">
                            <div className="col-sm-6 center_middle_left_service clearfix">
                                <div className="center_7_middle_left_inner_1 clearfix">
                                    <div className="card">
                                        <div className="card-image">
                                            <img className="img-responsive" src="img/36.png"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 service_2_middle_right clearfix">
                                <div className="service_2_middle_right_inner clearfix">
                                    <h2>OHIO: Cleveland Suburbs Must End Sewer Overflows to Lake Erie</h2>
                                    <p>The city of Elyria, a suburb of Cleveland, Wednesday agreed to complete <br/>a series of capital projects to eliminate discharges of untreated sewage<br/> from its sewer system into the Black River, 10 miles upstream from Lake Erie.<br/> The lake provides drinking water for at least 11 million people.</p>
                                        <h5><a href="https://ens-newswire.com/ohio-cleveland-suburb-must-end-sewer-overflows-to-lake-erie/" target="_">LEARN MORE</a></h5>
                                    </div>
                                    </div>
                        </div>
                        */}
                    </div>
                </Suspense>

            </div>
        </section>
    </>
    )
}

    export default NewsLetter


