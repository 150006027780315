import React,{useEffect} from 'react'

const Prices = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (        
            <>
            <section id="about" className="prices clearfix">
                <div className="about_head">
                    <div className="container">
                        <div className="row">
                            <div className="about_main clearfix">
                                <h2>Prices & Plans</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="center_3" className="clearfix">
                <div className="container">
                    <div className="row">
                        <div className="center clearfix">
                            <h2>Aptent taciti sociosqu ad litora torquent conubia.</h2>
                            <h4>Vestibulum lacinia arcu eget nulla className aptent.</h4>
                        </div>
                        <div className="center_3_middle clearfix">
                            <div className="col-sm-3 center_3_middle_left clearfix">
                                <div className="center_3_middle_left_inner_1 clearfix">
                                    <div className="grid clearfix">
                                        <figure className="effect-julia">
                                            <img src="img/7.jpg" alt="img21" />
                                            <figcaption>
                                                <a href="#">View more</a>
                                            </figcaption>
                                        </figure>

                                    </div>
                                </div>
                                <div className="center_3_middle_left_inner_2 clearfix">
                                    <h2><a href="#">Quis Sem</a></h2>
                                    <p>Praesent mauris Fusce nec tellus sed augue semper porta. Mauris massa vestibulum 8 am and 10 pm.</p>
                                </div>
                            </div>
                            <div className="col-sm-3 center_3_middle_left clearfix">
                                <div className="center_3_middle_left_inner_1 clearfix">
                                    <div className="grid clearfix">
                                        <figure className="effect-julia">
                                            <img src="img/8.jpg" alt="img21" />
                                            <figcaption>
                                                <a href="#">View more</a>
                                            </figcaption>
                                        </figure>

                                    </div>
                                </div>
                                <div className="center_3_middle_left_inner_2 clearfix">
                                    <h2><a href="#">Nec Tellus</a></h2>
                                    <p>Praesent mauris Fusce nec tellus sed augue semper porta. Mauris massa vestibulum 8 am and 10 pm.</p>
                                </div>
                            </div>
                            <div className="col-sm-3 center_3_middle_left clearfix">
                                <div className="center_3_middle_left_inner_1 clearfix">
                                    <div className="grid clearfix">
                                        <figure className="effect-julia">
                                            <img src="img/9.jpg" alt="img21" />
                                            <figcaption>
                                                <a href="#">View more</a>
                                            </figcaption>
                                        </figure>

                                    </div>
                                </div>
                                <div className="center_3_middle_left_inner_2 clearfix">
                                    <h2><a href="#">Nulla Quis</a></h2>
                                    <p>Praesent mauris Fusce nec tellus sed augue semper porta. Mauris massa vestibulum 8 am and 10 pm.</p>
                                </div>
                            </div>
                            <div className="col-sm-3 center_3_middle_left clearfix">
                                <div className="center_3_middle_left_inner_1 clearfix">
                                    <div className="grid clearfix">
                                        <figure className="effect-julia">
                                            <img src="img/10.jpg" alt="img21" />
                                            <figcaption>
                                                <a href="#">View more</a>
                                            </figcaption>
                                        </figure>

                                    </div>
                                </div>
                                <div className="center_3_middle_left_inner_2 clearfix">
                                    <h2><a href="#">Torquent</a></h2>
                                    <p>Praesent mauris Fusce nec tellus sed augue semper porta. Mauris massa vestibulum 8 am and 10 pm.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="center_8" className="clearfix">
                <div className="container">
                    <div className="row">
                        <div className="center_8_middle clearfix">
                            <div className="col-sm-3 center_8_middle_left clearfix">
                                <div className="center_8_middle_left_inner_1 clearfix">
                                    <h2>Quis Sem</h2>
                                    <p>Praesent mauris Fusce nec tellus sed augue semper porta. Mauris massa vestibulum 8 am and 10 pm.</p>
                                    <h4>$32.25</h4>
                                    <h5>ipsum</h5>
                                    <h6><a href="#">BUY NOW</a></h6>
                                </div>
                            </div>
                            <div className="col-sm-3 center_8_middle_left clearfix">
                                <div className="center_8_middle_left_inner_1 clearfix">
                                    <h2>Nec Tellus</h2>
                                    <p>Praesent mauris Fusce nec tellus sed augue semper porta. Mauris massa vestibulum 8 am and 10 pm.</p>
                                    <h4>$25.19</h4>
                                    <h5>ipsum</h5>
                                    <h6><a href="#">BUY NOW</a></h6>
                                </div>
                            </div>
                            <div className="col-sm-3 center_8_middle_left clearfix">
                                <div className="center_8_middle_left_inner_1 clearfix">
                                    <h2>Nulla Quis</h2>
                                    <p>Praesent mauris Fusce nec tellus sed augue semper porta. Mauris massa vestibulum 8 am and 10 pm.</p>
                                    <h4>$31.45</h4>
                                    <h5>ipsum</h5>
                                    <h6><a href="#">BUY NOW</a></h6>
                                </div>
                            </div>
                            <div className="col-sm-3 center_8_middle_left clearfix">
                                <div className="center_8_middle_left_inner_1 clearfix">
                                    <h2>Training</h2>
                                    <p>Praesent mauris Fusce nec tellus sed augue semper porta. Mauris massa vestibulum 8 am and 10 pm.</p>
                                    <h4>$22.49</h4>
                                    <h5>ipsum</h5>
                                    <h6><a href="#">BUY NOW</a></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            </>
            )
}
export default Prices


