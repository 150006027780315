import axios from 'axios';
import { APIBASE } from './constant';
export const httpRequest = (url, method, data, contentType, token) => {
    console.log(JSON.parse(localStorage.getItem('loggedin')));
    const localresponse = JSON.parse(localStorage.getItem('loggedin')) ? JSON.parse(localStorage.getItem('loggedin')) : { token: null };
    //console.log(localStorage.getItem('loggedin'));
    return new Promise(async function (resolve, reject) {
        let Url = APIBASE + url;
        axios({
            method: method,
            url: Url,
            headers: {
                'Content-Type': contentType,
                Accept: contentType,
                authorization: `Bearer ${localresponse.token}`
            },
            data: data
        })
            .then((res) => {
                return resolve(res.data);
            })
            .catch((error) => {
                return reject(error);
            });
    });
};
