import React,{useEffect, useState, Suspense} from 'react'
import Top from '../components/layout/Top'

import { httpRequest } from '../api';
import { APIBASE, GET, JSONCONTENTTYPE, HOMEPAGE2, HOMEPAGE3, HOMEPAGE4, HOMEPAGE5, HOMEPAGE6, HOMEPAGE7 } from '../api/constant';

const Home = () => {
    const [homepage2Data, setHomepage2Data] = useState({
        title: '',
        subTitle: '',
        files: [],
        submit: null
    });
    
    const [homepage3Data, setHomepage3Data] = useState({
        title: '',
        subTitle: '',
        files: [],
        submit: null
    });

    const [homepage4Data, setHomepage4Data] = useState({
        title: '',
        subTitle: '',
        files: [],
        submit: null
    });

    const [homepage5Data, setHomepage5Data] = useState({
        title: '',
        subTitle: '',
        name: '',
        link: '',
        file:'',
        submit: null
    });
    
    const [homepage6Data, setHomepage6Data] = useState({
        title: '',
        subTitle: '',
        name: '',
        link: '',
        file:'',
        submit: null
    });
    
    const [homepage7Data, setHomepage7Data] = useState({
        title: '',
        subTitle: '',
        name: '',
        link: '',
        files:[],
        submit: null
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        async function fetchHomepage2Data() {
            const getIntialData = await httpRequest(HOMEPAGE2 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.files.length>0) {
                    data.files.map((val)=>{
                        val.file= APIBASE + val.file.replace('public', '');
                    })
                }
                console.log('data', data);
                setHomepage2Data(data);
            }
        }
        
        async function fetchHomepage3Data() {
            const getIntialData = await httpRequest(HOMEPAGE3 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                console.log('data', data);
                setHomepage3Data(data);
            }
        }
        
        async function fetchHomepage4Data() {
            const getIntialData = await httpRequest(HOMEPAGE4 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                
                if (data.files.length>0) {
                    data.files.map((val)=>{
                        val.file= APIBASE + val.file.replace('public', '');
                    })
                }
                console.log('data', data);
                setHomepage4Data(data);
            }
        }

        async function fetchHomepage5Data() {
            const getIntialData = await httpRequest(HOMEPAGE5 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                
                data.file= APIBASE + data.file.replace('public', '');

                console.log('data', data);
                setHomepage5Data(data);
            }
        }

        async function fetchHomepage6Data() {
            const getIntialData = await httpRequest(HOMEPAGE6 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                
                data.file= APIBASE + data.file.replace('public', '');

                console.log('data', data);
                setHomepage6Data(data);
            }
        }
        
        async function fetchHomepage7Data() {
            const getIntialData = await httpRequest(HOMEPAGE7 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                
                if (data.files.length>0) {
                    data.files.map((val)=>{
                        val.file= APIBASE + val.file.replace('public', '');
                    })
                }
                console.log('data', data);
                setHomepage7Data(data);
            }
        }
        fetchHomepage2Data();
        fetchHomepage3Data();
        fetchHomepage4Data();
        fetchHomepage5Data();
        fetchHomepage6Data();
        fetchHomepage7Data();
        console.log('homepage2Data---->', homepage2Data);
        console.log('homepage3Data---->', homepage3Data);
        console.log('homepage4Data---->', homepage4Data);
        console.log('homepage5Data---->', homepage5Data);
        console.log('homepage6Data---->', homepage6Data);
        console.log('homepage7Data---->', homepage7Data);
    }, []);

    return (
        <>
            <Top/>
            <section id="center" className="clearfix">
                <Suspense fallback={<h1>Loading data...</h1>}>
                    <div className="container">
                        <div className="row">
                            <div className="center clearfix">
                                <h2>{homepage2Data.title} </h2>
                                <h4>{homepage2Data.subTitle}</h4>
                            </div>
                            <div className="center_middle clearfix">
                            {homepage2Data.files.length> 0 && homepage2Data.files.map( (val)=>{
                                    return (
                                        <div className="col-sm-4 center_middle_left clearfix">
                                            <div className="center_middle_left_inner_1 clearfix">
                                                <div className="grid clearfix">
                                                    <figure className="effect-milo">
                                                        <img src={val.file} alt="img03" />
                                                        <figcaption>
                                                            <a href="#">View more</a>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                            <div className="center_middle_left_inner_2 clearfix">
                                                <h5>{val.name}</h5>
                                                <h2><a href="#">{val.title} </a></h2>
                                                <p>{val.subTitle}</p>
                                            </div>
                                        </div>
                                )
                                })}
                                
                            </div>
                        </div>
                    </div>
                </Suspense>
            </section>

            <section id="center_2" className="clearfix">
                <Suspense fallback={<h1>Loading data...</h1>}>
                    <div className="container">
                        <div className="row">
                            <div className="center_2 clearfix">
                                <div className="col-sm-6 center_2_left clearfix"></div>
                                <div className="col-sm-6 center_2_right clearfix">
                                    <div className="center_2_right_inner">
                                        <h2>{homepage3Data.title}</h2>
                                        <h4>{homepage3Data.subTitle}</h4>
                                            {homepage3Data.files.length> 0 && homepage3Data.files.map( (val)=>{
                                                return (
                                                    <h5><i className="fa fa-check"></i><a href={val.link} target="_blank">{val.title}</a></h5>
                                                )
                                            })
                                            }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </section>

            <section id="center_3" className="clearfix">
            <Suspense fallback={<h1>Loading data...</h1>}>
                <div className="container">
                    <div className="row">
                        <div className="center clearfix">
                            <h2>{homepage4Data.title}</h2>
                            <h4>{homepage4Data.subTitle}</h4>
                        </div>
                        <div className="center_3_middle clearfix">
                            
                        {homepage4Data.files.length> 0 && homepage4Data.files.map( (val)=>{
                                return (
                                    <div className="col-sm-3 center_3_middle_left clearfix">
                                        <div className="center_3_middle_left_inner_1 clearfix">
                                            <div className="grid clearfix">
                                                <figure className="effect-julia">
                                                    <img src={val.file} alt="img21" />
                                                    <figcaption>
                                                        <a href="#">View more</a>
                                                    </figcaption>
                                                </figure>

                                            </div>
                                        </div>
                                        <div className="center_3_middle_left_inner_2 clearfix">
                                            <h2><a href="#">{val.title}</a></h2>
                                            <p>{val.subTitle}</p>
                                        </div>
                                    </div>
                                )
                        })}
                            
                        </div>
                    </div>
                </div>
                </Suspense>

            </section>

            <section id="service_2" class="clearfix">
                <Suspense fallback={<h1>Loading data...</h1>}>
                    <div class="container">
                        <div class="row">
                            <div class="center clearfix">
                                <h2>{homepage5Data.title}</h2>
                                <h4>{homepage5Data.subTitle}</h4>
                            </div>
                            <div class="service_2_middle clearfix">
                                <div class="col-sm-6 center_middle_left_service clearfix">
                                    <div class="center_6_middle_left_inner_1 clearfix">
                                        <div class="grid clearfix">
                                        <figure className="effect-julia">
                                                <img src={homepage5Data.file} alt="img21" />
                                                <figcaption>
                                                    <a href={homepage5Data.file} target="_">View more</a>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 service_2_middle_right clearfix">
                                    <div class="service_2_middle_right_inner clearfix">
                                        <h2>{homepage5Data.name}</h2>
                                        <p>{homepage5Data.description}</p>
                                            <h5><a href={homepage5Data.link}  target="_">LEARN MORE</a></h5>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Suspense>

            </section>

            <section id="center_6" className="clearfix">
                <Suspense fallback={<h1>Loading data...</h1>}>
                    <div className="container">
                        <div className="row">
                            <div className="center clearfix">
                                <h2>{homepage6Data.title} </h2>
                                <h4>{homepage6Data.subTitle}</h4>
                            </div>
                            <div className="center_6_middle clearfix">
                                <div className="col-sm-7 center_6_middle_left clearfix">
                                    <div className="center_6_middle_left_inner_1 clearfix">
                                        <div className="grid clearfix">
                                            <figure className="effect-jazz">
                                                <img src={homepage6Data.file} alt="img25" />
                                                <figcaption>
                                                    <a href="#">View more</a>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5 center_6_middle_right clearfix">
                                    <div className="center_6_middle_right_inner clearfix">
                                        <h4>{homepage6Data.name}</h4>
                                        <h5>{homepage6Data.description}</h5>
                                    </div>
                                    <div className="center_6_middle_right_inner_2 clearfix">
                                        <div className="col-sm-3 heart_left clearfix">
                                            <div className="heart_left_inner clearfix">
                                                <p><i className="fa fa-sun-o"></i></p>
                                                <h4>STREAMS</h4>
                                                <h4>WETLAND</h4>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 heart_left clearfix">
                                            <div className="heart_left_inner clearfix">
                                                <p><i className="fa fa-gear"></i></p>
                                                <h4>RIVER</h4>
                                                <h4>BANK</h4>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 heart_left clearfix">
                                            <div className="heart_left_inner clearfix">
                                                <p><i className="fa fa-clock-o"></i></p>
                                                <h4>ROAD </h4>
                                                <h4>HIGHWAY SIDE</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </section>
            
            <section id="center_5" className="clearfix">
                <Suspense fallback={<h1>Loading data...</h1>}>
                    <div className="testimonial_home_main clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="testimonial_home clearfix">
                                    <div className="carousel slide clearfix" id="fade-quote-carousel" data-ride="carousel" data-interval="3000">
                                        <ol className="carousel-indicators">
                                            <li data-target="#fade-quote-carousel" data-slide-to="0" className=""></li>
                                            <li data-target="#fade-quote-carousel" data-slide-to="1" className=""></li>
                                            <li data-target="#fade-quote-carousel" data-slide-to="2" className=""></li>

                                        </ol>
                                        <div className="carousel-inner">
                                            {homepage7Data.files.length> 0 && homepage7Data.files.map( (val,index)=>{
                                                let classname= 'item'
                                                if(index==0){
                                                    classname = 'item active left'
                                                }else if(index<homepage7Data.files.length){
                                                    classname = 'item next left'
                                                }
                                                return (
                                                    <div className={classname}>
                                                        <div className="profile-circle"  style={{background: 'url('+val.file+')'}}></div>
                                                        <p>{val.subTitle}</p>
                                                        <h4 className="text-center">{val.name}</h4>
                                                        <h6>{val.title}</h6>
                                                    </div>
                                                )
                                            })}
                                            {/*
                                            <div className="item next left">
                                                <div className="profile-circle_1"></div>
                                                <p>These three actions are focused on reducing waste, whether through the conservation of raw materials and energy, or the reuse and recycling of products.<br/> You can do your part in reducing waste by watching what you buy, supplying your own packaging,<br/> and carefully considering what you do with each item you purchase<br/> once it is no longer useful to you. There are also simple habits you can develop that will allow you to consume less water and electricity.</p>
                                                <h4 className="text-center">Reduce, Reuse, Recycle</h4>
                                                <h6>Three steps are crucial parts of saving our planet</h6>
                                            </div>
                                            <div className="item">
                                                <div className="profile-circle_2"></div>
                                                <p>Efforts to reduce the use of plastics, to promote plastic recycling and to reduce mismanaged plastic waste or plastic pollution have occurred or are ongoing. <br/>The first scientific review in the professional academic literature about global plastic pollution in general found that the rational response <br/>to the "global threat" would be "reductions in consumption of virgin plastic materials, along with internationally coordinated strategies for waste management.</p>
                                                <h4 className="text-center">Give Up Plastics</h4>
                                                <h6>Use Steps to reduce Plastic pollution </h6>
                                            </div>
                                             */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Suspense>

            </section>

        </>

    )
}

export default Home


