import { createSlice } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    value: 0
  },
  reducers: {
    changeIsActive: (state, action) => {
      state.value = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { changeIsActive } = layoutSlice.actions

export default layoutSlice.reducer
