import React, { Fragment } from 'react'
import '../../styles/login.css'
const Login = () => {

    return (
        <Fragment>
            <section id="login" className="clearfix">
                <div class="parent clearfix">
                    <div class="bg-illustration">
                        <img src="img/login.png" alt="logo" />
                            <div class="burger-btn">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                    </div>

                    <div class="login">
                        <div class="container">
                            <h1>Login to access to<br />your account</h1>
                            <div class="login-form">
                                <form action="">
                                    <input type="email" placeholder="E-mail Address" />
                                        <input type="password" placeholder="Password" />
                                            <div class="remember-form">
                                                <input type="checkbox" />
                                                    <span>Remember me</span>
                                            </div>
                                            <div class="forget-pass">
                                                <a href="#">Forgot Password ?</a>
                                            </div>
                                            <button type="submit">LOG-IN</button>
                                        </form>
                                    </div>
                            </div>
                        </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Login


