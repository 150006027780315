import React,{useState} from 'react'
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import {
    changeIsActive
} from '../../context/example/layoutSlice'
const Header = (props) => {
const isActive = useSelector((state) => state.counter.value)
const dispatch = useDispatch()

    return (
        <>
            <section id="header" className="clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 clearfix">

                            <nav className="navbar navbar-default navbar-fixed-top" role="navigation">

                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                    <Link to='/' className="navbar-brand"><img src="img/2.jpg" alt="Image Not Available" className="img-circle" style={{height:'60px'}}/></Link>
                                </div>

                                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">


                                    <ul className="nav navbar-nav navbar-right">
                                        <li className={(isActive=='' || isActive=='/')?"active":''}><Link to='/' onClick={()=>dispatch(changeIsActive('/'))}>Home</Link></li>
                                        <li className={isActive=='about'?"active":''}><Link to='/about' onClick={()=>dispatch(changeIsActive('about'))}>About</Link></li>
                                        <li className={isActive=='newsletter'?"active":''}><Link to='/newsletter' onClick={()=>dispatch(changeIsActive('newsletter'))}>News Letter</Link></li>
                                        <li className={isActive=='team'?"active":''}><Link to='/team' onClick={()=>dispatch(changeIsActive('team'))}>Team</Link></li>
                                        <li className={isActive=='gallery'?"active":''}><Link to='/gallery' onClick={()=>dispatch(changeIsActive('gallery'))}>Gallery</Link></li>
                                        <li className={isActive=='contact'?"active":''}><Link to='/contact' onClick={()=>dispatch(changeIsActive('contact'))}>Contact Us</Link></li>
                                        
                                        <li className="dropdown"><a href="blog.html" data-toggle="dropdown"><span className="glyphicon glyphicon-search"></span></a>
                                            <ul className="dropdown-menu drop_1" style={{ minWidth: "300px" }}>
                                                <li>
                                                    <div className="row_1">
                                                        <div className="col-sm-12">
                                                            <form className="navbar-form navbar-left" role="search">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Search" />
                                                                    <span className="input-group-btn">
                                                                        <button className="btn btn-primary" type="button">
                                                                            Go!</button>
                                                                    </span>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Header


